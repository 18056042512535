
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import StepTracker from "@/components/request/StepTracker.vue";
import SearchService from "@/components/request/SearchService.vue";
import SearchServiceNew from "@/components/request/SearchServiceNew.vue";
import DialogService from "@/components/request/DialogService.vue";

@Component({
  components: {
    LogoSingle,
    StepTracker,
    SearchService,
    SearchServiceNew,
    DialogService,
    IdentifyUser: () => import("@/components/request/IdentifyUser.vue"),
    SendRequest: () => import("@/components/request/SendRequest.vue"),
    EndRequest: () => import("@/components/request/EndRequest.vue")
  }
})
export default class NewRequest extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private service: any;
  service_selected: any = "";
  motive_selected: any = {};

  private mounted() {
    this.$store.dispatch("setStateToCero");
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);

    this.$axios
      .post("servicios/url", {
        id_servicio: Number(this.$route.params.service_id)
      })
      .then(response => {
        if (response.data != null) {
          if (response.data.id_tipo_servicio != 2) {
            this.service = response.data;
            let ticket: any = {
              step: 1,
              service_id: response.data.id,
              service_name: response.data.nombre,
              motive: Number(this.motive_selected.value)
            };

            this.$store.dispatch("submitRequestStepOne", ticket);
          } else {
            // presencial
            document.location.assign(response.data.servicio_url[0].url);
          }
        } else {
          this.$router.push({ name: "newrequest" });
        }
      })
      .catch(error => {});
  }

  private selectService(e: any) {
    if (e) {
      this.service = e;
    }
  }
}
