
import { Component, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import DialogService from "@/components/request/DialogService.vue";

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    DialogService
  }
})
export default class SearchService extends Mixins(FormValidator) {
  private url_ley_karin = process.env.VUE_APP_LEY_KARIN_URL;

  private base_bucket = process.env.VUE_APP_AWS_ASSETS;

  private url_front = process.env.VUE_APP_AWS_ASSETS as string;
  private slidePopUp = 0;
  services = [];
  categorias: Array<any> = [];
  options_services: any[] = [];
  service_selected: any = "";
  category_selected: any = {};
  filter_string: string = "";
  servicio: boolean = false;
  servicios: any;
  serviciosCat: Array<any> = [];
  servicio_tag: Array<any> = [];
  private catName = "";
  private aServicesCat: Array<any> = [];
  private servicesOptions: Array<any> = [];
  private text = "";
  private url: any;
  private winSize: boolean = false;
  private window = {
    width: 0,
    height: 0
  };

  text1 = "";

  private catService: Tipos = {
    value: null,
    label: ""
  };

  private ley_karin: any = {
    id: -1,
    nombre: "Trámites",
    descripcion: "Categoría que redirecciona a ley karin",
    servicios: [{ id: -1 }],
    categoria_icon: [
      {
        id: 1,
        id_categoria: 1,
        icono: "/iconos/icons8-documento-50_1720530686246.png",
        svg: false
      }
    ]
  };

  @Watch("text")
  private searchService(newValue: any) {
    if (newValue.length >= 3) {
      this.filterFnServices(newValue);
    } else {
      this.servicesOptions = this.serviciosCat;
    }
  }

  private created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  private destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  private beforeCreate() {
    this.$q.loading.show();
    this.url = process.env.VUE_APP_AWS_ASSETS;
  }

  private handleResize() {
    if (window.innerWidth >= 1024 && window.innerWidth <= 1439) {
      this.winSize = true;
    } else if (window.innerWidth >= 1440 && window.innerWidth <= 1919) {
      this.winSize = true;
    } else if (window.innerWidth >= 1920) {
      this.winSize = true;
    } else {
      this.winSize = false;
    }
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
  }

  private getCategorias() {
    for (let index = 0; index < this.categorias.length; index++) {
      this.categorias[index].categoria_icon[0].icono =
        this.url + this.categorias[index].categoria_icon[0].icono;
    }
  }

  private categoriaDialog(categoria: any) {
    this.servicesOptions = [];
    this.catName = categoria.nombre;
    this.servicesOptions = categoria.servicios;
    this.servicio = true;
  }

  private filterFnServices(val: any) {
    const value = val.toLowerCase();
    this.servicesOptions = this.serviciosCat.filter((service: any) => {
      let filtered =
        service.nombre.toLowerCase().indexOf(value) > -1 ||
        service.descripcion.toLowerCase().indexOf(value) > -1;

      return filtered;
    });
  }

  private cerrarDialog() {
    this.servicio = false;
    this.serviciosCat = [];
    this.servicio_tag = [];
    this.aServicesCat = [];
    this.catService.value = null;
    this.catService.label = "";
    this.catName = "";
    this.service_selected = "";
  }

  private vaciarInput(e: any) {
    if (e == false) {
      this.service_selected = "";
    }
  }

  private stepOneSubmit() {
    if (Object.keys(this.service_selected).length) {
      let ticket: any = {
        step: 1,
        service_id: this.service_selected.id,
        service_name: this.service_selected.nombre,
        service_description: this.service_selected.descripcion
      };

      this.$store.dispatch("submitRequestStepOne", ticket);
    } else {
      this.$q.notify({
        message: this.$t("notification.no_service_selected") as string,
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }
  }

  private moreHelpRequested() {
    this.stepOneSubmit();
  }

  private async scrollToCategory(category_id: any) {
    let expansion_item = "expansion-" + category_id;
    let options = {
      behavior: "smooth",
      alignToTop: true
    };
    //sorry
    await new Promise(resolve => setTimeout(resolve, 375));
    this.$nextTick(() => {
      document.getElementById(expansion_item)!.scrollIntoView(options as any);
    });
  }

  private selectService(service: any): void {
    if (service && service.id == -1) {
      window.open(this.url_ley_karin, "_self");
    } else {
      if (service.id_tipo_servicio != 2) {
        this.$emit("service", service);
        this.service_selected = service;
        this.stepOneSubmit();
      } else {
        // presencial
        this.service_selected = service;
        document.location.assign(service.servicio_url[0].url);
      }
    }
  }

  private boldCoincidence(str: string, query: string): string {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    return (
      str.substr(0, x) +
      "<b>" +
      str.substr(x, q.length) +
      "</b>" +
      str.substr(x + q.length)
    );
  }

  private filterService(val: string, update: any, abort: any) {
    this.filter_string = val;
    if (val.length < 3) {
      abort();
      return;
    }
    update(() => {
      const value = val.toLowerCase();

      this.options_services = this.services.filter((service: any) => {
        let filtered =
          service.nombre.toLowerCase().indexOf(value) > -1 ||
          service.descripcion.toLowerCase().indexOf(value) > -1 ||
          (!!service.servicio_tag &&
            service.servicio_tag.some((servicio_tag: any) => {
              return (
                servicio_tag.tag &&
                servicio_tag.tag.nombre.toLowerCase().includes(value)
              );
            }));
        return filtered;
      });
    });
  }

  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  popup_dialog = false;
  multi_dialog = false;
  single_popup: any = null;
  multiple_popups: any = null;

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private clickPopUp(popup: any) {
    if (popup && popup.url && popup.url != "") {
      window.open(popup.url, "_self");
    }
  }

  private showPopUp(object: object) {
    this.single_popup = object;
    this.popup_dialog = true;
  }

  private clearPopUps() {
    this.multi_dialog = false;
    this.popup_dialog = false;
    this.single_popup = null;
    this.multiple_popups = null;
  }

  private getFrontImages() {
    this.clearPopUps();
    this.$axios
      .get("pop-ups/visualizar/front")
      .then(response => {
        if (response.data.length > 1) {
          this.multi_dialog = true;
          this.multiple_popups = response.data;
        } else if (response.data.length == 1) {
          this.showPopUp(response.data[0]);
        }
      })
      .catch(error => {})
      .finally(() => {});
  }

  private mounted() {
    this.$q.loading.show();
    this.$gtag.event("Seleccionar Servicio", {
      event_label: "Seleccionar servicio",
      event_category: "test",
      value: 1
    });
    window.scroll(0, 0);
    this.getFrontImages();
    this.$axios
      .get("servicios/listar/2")
      .then(response => {
        this.services = response.data.servicios;
        this.servicios = this.services;
        this.categorias = response.data.categorias;
        this.categorias.push(this.ley_karin);
        // this.getCategorias();
        /* dgongora 20210217 - modificada la rutina que selecciona el servicio.
           Rutina anterior no encontraba el servicio correctamente usando find sobre el arreglo
           Posiblemente debido a que objetos reactivos no se pueden manipular normalmente.
        */
        let service_id: any = this.$route.params.service_id;
        if (service_id !== "") {
          let lookup: any[] = [];
          let temp: any = {};
          for (var i = 0, len = this.services.length; i < len; i++) {
            temp = this.services[i];
            lookup[temp!.id] = this.services[i];
          }
          let service = lookup[service_id];
        }
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.problem") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }
}
